



































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsFixedBottomActions from '@/components/VsFixedBottomActions/Index.vue'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsCreateWebServiceModal from '@/modules/lists/components/VsCreateWebServiceModal/Index.vue'
import VsOptinEmailModal from '@/modules/lists/components/VsOptinEmailModal/Index.vue'
import {
    getList,
    getListCustomFields,
    getLists,
    updateList,
    getListWebServices,
} from '@/api/consoleApi/recipients'
import {
    deleteWebService,
    createWebService,
} from '@/api/consoleApi/webServices'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    CustomField,
} from '@/utils/customFields'
import { AppModule } from '@/store/modules/app'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import { createEmail, deleteEmail, getEmail } from '@/api/consoleApi/contents/emails'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import { getSendersEmail } from '@/api/consoleApi/senders/emails'

@Component({
    name: 'EditList',
    components: {
        VsFixedBottomActions,
        VsSectionHeader,
        VsFormGroup,
        VsFormSection,
        VsCreateWebServiceModal,
        VsPreviewModal,
        VsOptinEmailModal,
        VsLoader,
        VsConfirm,
        VsTable,
    },
})
export default class extends Vue {
    private loading = false
    private loadEditEmail = false
    private title = ''
    private lists: any[] = []
    private list: any = null
    private customFields: CustomField[] = []
    private webServices: any[] = []
    private tableColumns: any[] = []
    $refs: any

    beforeMount () {
        this.getList()
        this.getListCustomFields()
        this.getLists()
        this.getWebServices()
        this.getTableHeaders()
    }

    get user () {
        return UserModule.user
    }

    get optinSubject () {
        return AppModule.optinSubject
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get userCompleteName () {
        return UserModule.userCompleteName()
    }

    private async getList () {
        try {
            const resp = await getList(this.listId)
            this.list = resp.data.data
            this.title = resp.data.data.name
            delete this.list.integration
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'listsIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async getListCustomFields () {
        try {
            this.customFields = await this.customFieldsLoop(1)
        } catch (e) {
            this.customFields = []
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati dei campi personalizzati, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async customFieldsLoop (page: number): Promise<CustomField[]> {
        const resp = await getListCustomFields(this.listId, { page })
        const customFields: CustomField[] = resp.data.data
        let fields: CustomField[] = []
        if (
            resp.data.meta.pagination.current_page < resp.data.meta.pagination.total_pages &&
            resp.data.meta.pagination.current_page === page
        ) {
            page = page + 1
            fields = await this.customFieldsLoop(page)
        }
        return [
            ...customFields,
            ...fields,
        ]
    }

    async getLists () {
        try {
            const response = await getLists()
            this.lists = []
            const recip: any[] = []
            response.data.data.forEach((e: any) => {
                recip.push({
                    value: e.id,
                    label: e.name,
                })
            })
            this.lists = recip
        } catch (e) {
            this.lists = []
            console.log(e)
        }
    }

    async getWebServices () {
        try {
            const resp = await getListWebServices(this.listId)
            this.webServices = resp.data.data
        } catch (e) {
            this.webServices = []
            console.log(e)
        }
    }

    async createWebService (webService: any) {
        this.$refs.createWebServiceModal.loading = true
        webService.recipient_id = this.listId
        try {
            await createWebService(webService)
            await this.getWebServices()
            this.$refs.createWebServiceModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Web service creato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la creazione del web service',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.$refs.createWebServiceModal.loading = false
    }

    async submit () {
        const valid = await this.$refs.editListForm.validate()
        if (!valid) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Ci sono errori controlla i campi',
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        this.loading = true
        this.list.email_address_notify = this.list.email_address_notify || ''

        try {
            await updateList(this.listId, this.list)
            this.title = this.list.name
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Impostazioni salvate con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante il salvataggio delle modifiche',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private formatData () {
        return {
            name: this.list.name,
            hide_in_subscriber_area: this.list.hide_in_subscriber_area,
            send_activity_notification: this.list.send_activity_notification,
            email_address_notify: this.list.email_address_notify,
            subscription: {
                confirmed_page_url: this.list.subscription.confirmed_page_url,
                pending_page_url: this.list.subscription.pending_page_url,
                error_page_url: this.list.subscription.error_page_url,
            },
            unsubscription: {
                confirmed_page_url: this.list.unsubscription.error_page_url,
                error_page_url: this.list.unsubscription.error_page_url,
            },
            opt_in: {
                email_id: this.list.opt_in.email_id || undefined,
                mode: this.list.opt_in.mode.toLowerCase(),
                subscribe_to: this.list.opt_in.subscribe_to || undefined,
                unsubscribe_from: this.list.opt_in.unsubscribe_from || undefined,
            },
            opt_out: {
                scope: this.list.opt_out.scope === 'all_lists' ? 'all_lists' : 'this_list',
                mode: this.list.opt_out.mode.toLowerCase(),
                add_to_global_suppression: this.list.opt_out.add_to_global_suppression,
                add_to_local_suppression: this.list.opt_out.add_to_local_suppression,
                subscribe_to: this.list.opt_out.subscribe_to || undefined,
                unsubscribe_from: this.list.opt_out.unsubscribe_from || undefined,
            },
        }
    }

    private async openDefaultPreview () {
        const defaultSender = await this.getDefaultSender()
        const emailParams = {
            subject: this.optinSubject,
            from_email: defaultSender ? defaultSender.email : this.user.email,
            from_name: defaultSender ? defaultSender.name : `${this.userCompleteName}`,
            id: 'optin',
        }

        this.$refs.vsPreviewModal.openModalNew(emailParams, 'email')
    }

    private async getDefaultSender () {
        try {
            this.loading = true
            const resp = await getSendersEmail({
                page: 1,
                limit: 1,
                searchJoin: 'and',
                search: 'default:1',
            })
            return resp.data.data[0]
        } catch (e) {
            console.log(e)
            return null
        }
    }

    private async deleteConfirmationEmail () {
        if (!this.list.opt_in.email_id) return
        try {
            await this.$refs.vsConfirmDelete.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteEmail(this.list.opt_in.email_id)
            this.list.opt_in.email_id = 0
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dell\'email di conferma',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private async deleteIntegrationWebService (webServiceId: any) {
        try {
            await this.$refs.vsConfirmDeleteWebservice.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteWebService(webServiceId)
            await this.getWebServices()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Web service eliminato con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del web service',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private async createEmailAndOpenModal () {
        try {
            const resp = await createEmail()
            this.$refs.vsOptinEmailModal.openModal(resp.data)
        } catch (e) {
            console.log(e)
        }
    }

    private async openEditEmailModal () {
        if (!this.list.opt_in.email_id) return
        this.loadEditEmail = true
        try {
            const resp = await getEmail(this.list.opt_in.email_id)
            this.$refs.vsOptinEmailModal.openEditModal(this.list.opt_in.email_id, resp.data.data)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'apertura dell\'email di conferma',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loadEditEmail = false
    }

    private confirmationEmailSaved (emailId: string) {
        this.list.opt_in.email_id = emailId
    }

    private getTableHeaders () {
        const tableColumns: TableHeader[] = []

        tableColumns.push(
            {
                id: 'id',
                key: 'id',
                sortable: false,
                label: 'ID',
                visible: true,
            },
            {
                id: 'url',
                key: 'url',
                sortable: false,
                label: 'URL',
                visible: true,
            },
            {
                id: 'type',
                key: 'type',
                sortable: false,
                label: 'Si scatena',
                visible: true,
            },
        )

        this.tableColumns = tableColumns
    }
}
