import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getWebServices = (params: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/web_services`,
        {
            params,
            cancelToken,
        },
    )

export const createWebService = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/web_services`,
        data,
        {
            cancelToken,
        },
    )

export const deleteWebService = (webServeceId: string | number, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/web_services/${webServeceId}`,
        {
            cancelToken,
        },
    )
