







import { Component, Prop, Vue } from 'vue-property-decorator'

export enum VsFixedBottomActionsVariantEnum {
    'full-width' = 'full-width',
    'narrow' = 'narrow',
}

export enum VsFixedBottomActionsMarginEnum {
    'auto' = 'auto',
    'none' = 'none',
}

export enum VsFixedBottomActionsPositionEnum {
    'relative' = 'relative',
    'fixed' = 'fixed',
    'sticky' = 'sticky',
}

@Component({
    name: 'VsFixedBottomActions',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: VsFixedBottomActionsMarginEnum.auto,
    }) margin?: VsFixedBottomActionsMarginEnum

    @Prop({
        required: false,
        default: VsFixedBottomActionsVariantEnum['full-width'],
    }) variant?: VsFixedBottomActionsVariantEnum

    @Prop({
        required: false,
        default: VsFixedBottomActionsPositionEnum.fixed,
    }) position?: VsFixedBottomActionsPositionEnum

    @Prop({
        required: false,
        default: true,
        type: Boolean,
    }) sidebar?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noPadding?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noXPadding?: boolean

    @Prop({
        required: false,
        default: '',
    }) actionsStickyDistance?: string

    get dynamicStyles () {
        return {
            'max-width': this.maxWidth,
        }
    }

    get dynamicActionsContainerClasses () {
        return {
            'vs-actions-fixed-container': true,
            'vs-sticky': this.position === VsFixedBottomActionsPositionEnum.sticky,
            'vs-fixed': !this.position || this.position === VsFixedBottomActionsPositionEnum.fixed,
            'vs-bottom-0': true,
            'vs-left-0': !this.position || this.position === VsFixedBottomActionsPositionEnum.fixed,
            'vs-right-6': !this.position || this.position === VsFixedBottomActionsPositionEnum.fixed,
            'vs-left-sidebar-distance': this.sidebar && this.position === VsFixedBottomActionsPositionEnum.fixed,
        }
    }

    get dynamicActionsClasses () {
        return {
            'vs-fixed-bottom-actions': true,
            'vs-mx-auto': VsFixedBottomActionsMarginEnum.auto,
            'vs-box-content': true,
            'vs-px-4': !this.noPadding && !this.noXPadding,
            'md:vs-px-12': !this.noPadding && !this.noXPadding,
            'xl:vs-px-16': !this.noPadding && !this.noXPadding,
            'vs-actions-fixed': true,
            'vs-py-4': true,
            'vs-bg-white': true,
            'vs-border-grey-100': true,
        }
    }

    get maxWidth () {
        if (this.margin === VsFixedBottomActionsMarginEnum.none) return 'none'
        if (this.variant === VsFixedBottomActionsVariantEnum.narrow) return '744px'
        return '1184px'
    }
}
