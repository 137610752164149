var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.webServiceModal.addField'))+" ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('lists.webServiceModal.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('lists.webServiceModal.save'))+" ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"webServiceForm"},[_c('VsFormGroup',[_c('VsSelect',{attrs:{"label":_vm.$t('lists.webServiceModal.trigger'),"options":[
                    {
                        label: _vm.$t('lists.webServiceModal.atSubscription'),
                        value: 'subscription',
                    },
                    {
                        label: _vm.$t('lists.webServiceModal.atUnubscription'),
                        value: 'unsubscription',
                    } ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('VsFormGroup',[_c('VsLabel',[_vm._v(" "+_vm._s(_vm.$t('lists.webServiceModal.url'))+" ")]),_c('div',{staticClass:"vs-flex vs-items-start vs-gap-2"},[_c('VsSelect',{staticClass:"vs-flex-none",staticStyle:{"max-width":"120px"},attrs:{"labelHidden":"","options":[
                        {
                            label: _vm.$t('lists.webServiceModal.https'),
                            value: 'https://',
                        },
                        {
                            label: _vm.$t('lists.webServiceModal.http'),
                            value: 'http://',
                        } ]},model:{value:(_vm.urlPrefix),callback:function ($$v) {_vm.urlPrefix=$$v},expression:"urlPrefix"}}),_c('ValidationProvider',{attrs:{"vid":"webServiceUrl","name":"Url","rules":"required|url","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-flex-auto",attrs:{"labelHidden":"","error":errors.length > 0,"errorMessage":errors[0],"placeholder":_vm.$t('lists.webServiceModal.insertUrl')},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }