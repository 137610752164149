
















































































import { Component, Vue } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'

@Component({
    name: 'VsCreateWebServiceModal',
    components: {
        VsFormGroup,
    },
})
export default class extends Vue {
    private open = false
    private type = 'subscription'
    private url = ''
    private urlPrefix = 'https://'
    public loading = false
    $refs: any

    private async submit () {
        if (this.loading) return
        const valid = await this.$refs.webServiceForm.validate()
        if (!valid) return
        this.$emit('submit', {
            type: this.type,
            url: this.urlPrefix + this.url,
        })
    }

    public openModal () {
        this.type = 'subscription'
        this.url = ''
        this.urlPrefix = 'https://'
        this.open = true
    }

    public closeModal () {
        this.open = false
    }
}
