













































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import { CustomField } from '@/utils/customFields'
import VsEmailSenderCard from '@/modules/campaigns/components/VsEmailSenderCard/Index.vue'
import VsEmailSubjectCard from '@/modules/campaigns/components/VsEmailSubjectCard/Index.vue'
import VsEmailContentCard from '@/modules/campaigns/components/VsEmailContentCard/Index.vue'
import { deleteEmail, getEmail } from '@/api/consoleApi/contents/emails'
import { IMessageEmail } from '@/api/consoleApi/dto/campaigns.dto'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { updateList } from '@/api/consoleApi/recipients'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsOptinEmailModal',
    components: {
        VsContainer,
        VsEmailSenderCard,
        VsSectionHeader,
        VsFullTopBarLayout,
        VsEmailSubjectCard,
        VsEmailContentCard,
        VsConfirm,
    },
})
export default class extends Vue {
    private open = false
    @Prop({ required: false, default: false, type: Boolean }) isEdit!: boolean
    private loading = false
    private customFields: CustomField[] = []
    private email: IMessageEmail | null = null
    private messageId = ''
    private tabOpen = ''
    private firstSelectedListCustomFields = []
    private contentType = 'email'
    private emailError = false
    private missingSteps: any[] = []
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    private calculateMissingSteps () {
        const missingSteps = []
        if (this.$refs.vsEmailSenderCard && (this.$refs.vsEmailSenderCard.senderCardStatus === 'error' || this.$refs.vsEmailSenderCard.senderCardStatus === 'default')) missingSteps.push('sender')
        if (this.$refs.VsEmailSubjectCard && this.$refs.VsEmailSubjectCard.subjectCardStatus === 'default') missingSteps.push('subject')
        if (this.$refs.vsEmailContentCard && (this.$refs.vsEmailContentCard.campaignContentStatus === 'error' || this.$refs.vsEmailContentCard.campaignContentStatus === 'default')) missingSteps.push('content')
        this.missingSteps = missingSteps
    }

    emailHasError () {
        this.calculateMissingSteps()
        return this.missingSteps.length > 0
    }

    public async openModal (messageId: string) {
        this.loading = true
        this.messageId = messageId
        await this.getEmail()
        this.open = true
        this.loading = false
    }

    public openEditModal (messageId: string, email: any) {
        this.loading = true
        this.messageId = messageId
        this.email = email
        this.open = true
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    private openTab (tabName: string) {
        this.tabOpen = tabName
    }

    private async getEmail () {
        try {
            this.emailError = false
            const resp = await getEmail(this.messageId)
            this.email = resp.data.data
        } catch (e) {
            this.email = null
        }
    }

    private async closeAndDelete () {
        if (!this.isEdit || this.emailHasError()) {
            try {
                await this.$refs.vsConfirmExit.openConfirm()
            } catch (e) {
                return
            }
            try {
                await deleteEmail(this.messageId)
                this.$emit('confirmation-email-saved', 0)
            } catch (e) {
                console.log(e)
            }
        }
        this.closeModal()
    }

    private async save () {
        if (this.emailHasError()) {
            this.emailError = true
            this.$root.$vsToast({
                heading: this.$t('lists.optinEmailModal.optinEmailSavedErrorLocal'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        try {
            await updateList(this.listId, {
                opt_in: {
                    email_id: this.messageId,
                },
            })
            this.$emit('confirmation-email-saved', this.messageId)
            this.$root.$vsToast({
                heading: this.$t('lists.optinEmailModal.optinEmailSaved'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('lists.optinEmailModal.optinEmailSavedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
